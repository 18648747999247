<template>
    
    <div class="header bg-med align-center pt-4 pb-2 my-5">
        <div class="row justify-content-center">
            <div class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div v-for="(text, index) in texts" :key="index" :class="['carousel-item', index==0?'active':'']" data-bs-interval="6000">
                        <h1 class="pb-3 font-weight-bold display-3">
                            <strong>{{ text }}</strong>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

export default({
    name: 'Header',
    props: ['texts'],
    mounted() { }
    
})
</script>
