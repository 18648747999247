<template>
    <div class="w-100">
        <Navbar :pagetitle='pagetitle' :navitems="navitems" @downloadCall="showDowns=!showDowns" />
        <Download v-if="showDowns" @close="showDowns=false" />
        <Header :texts="header" class="hidden"/>
        <ImgAside :data="first" />
        <ImgAside  :data="second"/>
        <Modal v-if="showModal" @close="showModal=false" />
        <div class="empty-50" />
        <Footer @modalCall="showModal=true" />
    </div>
</template>
<script>
import Modal    from '@/components/Modal'
import Navbar   from '@/components/Navbar'
import Header   from '@/components/Header'
import Footer   from '@/components/Footer'
import Download from '@/components/Downloads'
import ImgAside from '@/components/ImageAside'
import n_1st    from '@/assets/resized/nuez/1.png'
import n_2nd    from '@/assets/resized/nuez/4.png'

export default({
    name: 'nuez',
    components: { Navbar, Download, Header, ImgAside, Footer, Modal },
    data(){ return {
        showDowns:false,
        showModal:false,
        pagetitle: 'nuez', 
        navitems: [
            { title: 'nuez', route: 'nuez' },
            { title: 'gui',  route: 'gui' }, 
            { title: 'settings', route: 'settings' }, 
            { title: 'mongodb',  route: 'mongodb' },
        ],
        header: [''],
        first: {
            side: 'right',
            title: 'Preamble',
            description: 'This is not an alternative to facebook or telegram!',
            section: [
                { icon: 'fab fa-facebook', 
                  text: '..and this version never was ment to be. when you take a look at this page you will notice that everything here is a progress. of course this app is still under development. and since I prefer to work alone, it will be ages before I´ll be ´satisfied´ with it. (..honestly: i never will..)'
                }, { icon: 'fas fa-magnet',
                     text: '´nuez´ is another stepstone on my way. i started with a usenet-version that´s hidden deactivated inside the code. i rejected that idea for the paid access you need to post on usenet. ..well, most of the time i do not have that money left, so how can i expect you to..'
                }, { icon: 'far fa-moon',
                     text: 'final step for ´nuez´ will be a onion-version. due to the facts that i have to work for a living and this will take some more time: here you go.. this one works fine with a free mongo database.. read more in ´mongodb´.'
                }, {
                    icon: 'fab fa-korvue',
                    text: '..oh: the general idea? ain´t that obvious? fuck censorship. ´freedom of speech´! oh, buddy.. they´ve really been overdoing it lately.'
                }
            ],
            image: n_1st,
        },
        second: {
            side: 'left',
            image: n_2nd,
            title: '',
            description: '..so why another app?',
            section: [
                {
                    icon: 'fas fa-desktop',
                    text: 'main thing: privacy. all your ´personal´ data is stored locally, on your machine. obviously someone has to run a server or register with mongodb. but anyone with access to that server cannot do anything with your data, because..'

                },
                                {
                    icon: 'fas fa-server',
                    text: 'what you share will be encrypted: by a key that the owner of a group creates on his own. you find something of interest in ´groups´ you want to join, you need to send a request for that special key. inconvenient, but relatively secure.'

                },
                                {
                    icon: 'fas fa-inbox',
                    text: 'sending and receiving requests is done through your mail-client and your provider: chose wisely, i recommend protonmail or similar. '

                },
                                {
                    icon: 'fas fa-photo-video',
                    text: 'this in advance: e v e r y t h i n g  you want to share will be encrypted. this includes data as well. images will be resized, similar to that ´other´-app, before upload. shrink your pdf-files before sharing. videos will take forever and a day up- and downloading, so.. link them, do not upload. i´m planning a LBRY plugin for a future release.'

                },
            ]
        },
    }}
})
</script>
<style scoped>

</style>
